import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { ICompany, getCompanyBySlug } from "data/company";
import { ROUTES } from "helpers/routes";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import theme from "theme";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      paddingBlock: theme.spacing(2),
      paddingInline: theme.spacing(6),
    },
    closeButton: {
      right: 0,
      position: "absolute",
      backgroundColor: theme.palette.common.white,
      top: theme.spacing(1),
      transform: "translate(150%, -50%)",
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(6),
  },
}))(MuiDialogContent);

const CompanyDetails = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState<ICompany>();
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      if (window.history?.length && window.history.length > 1) {
        navigate(-1);
      } else {
        navigate(ROUTES.HOME, { replace: true });
      }
    }, 100);
  };

  useEffect(() => {
    if (slug) {
      const com = getCompanyBySlug(slug);
      setCompany(com);
    }
  }, [slug]);

  useEffect(() => {
    if (company !== undefined) {
      setTimeout(() => setOpen(true), 100);
    }
  }, [company]);

  return (
    <Dialog
      open={open}
      maxWidth={false}
      PaperProps={{
        style: {
          borderRadius: 30,
          width: "80%",
          overflow: "visible",
        },
      }}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Stack spacing={2} direction="row">
          <img
            src={company?.logo}
            alt={company?.name}
            style={{
              height: 100,
              padding: theme.spacing(2),
              borderRadius: theme.spacing(2),
              border: `1px solid ${theme.palette.divider}`,
              objectFit: "contain",
            }}
          />
          <Stack justifyContent="space-between">
            <Typography variant="h4">{company?.name}</Typography>
            <Stack spacing={2} direction="row">
              {company?.industry?.map((industry) => (
                <Chip label={industry} style={{ backgroundColor: "#EFF5FF" }} />
              ))}
              {company?.stage && (
                <Chip
                  label={company?.stage}
                  style={{ backgroundColor: "#E9FCCB" }}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={4.5}>
          <Stack>
            <Typography gutterBottom variant="h5">
              Description
            </Typography>
            <Typography gutterBottom>{company?.description}</Typography>
          </Stack>
          <Typography gutterBottom variant="h5">
            Market:{" "}
            <Typography component="span" variant="body1">
              {company?.market}
            </Typography>
          </Typography>
          <Stack>
            <Typography gutterBottom variant="h5">
              {company?.founder?.title}
            </Typography>
            <Stack
              direction="row"
              spacing={2.5}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Box style={{ aspectRatio: 300 / 242 }}>
                <img
                  width={300}
                  height="auto"
                  src={company?.founder?.photo}
                  alt={company?.name}
                />
              </Box>
              <Stack>
                <Typography variant="h6">{company?.founder?.name}</Typography>
                <Stack
                  component="ul"
                  spacing={1}
                  style={{ fontSize: theme.typography.fontSize }}
                >
                  {company?.founder?.past?.map((p, index) => (
                    <Box
                      component="li"
                      key={index}
                      dangerouslySetInnerHTML={{ __html: p.title }}
                    />
                  ))}
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  {company?.founder?.past?.map((p, _idx) => {
                    return p.logo?.map((logo, index) => (
                      <img
                        style={{ maxWidth: 100 }}
                        key={`${_idx}_${index}`}
                        src={`/img/companies/logos/${logo}`}
                        alt={p.title}
                      />
                    ));
                  })}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyDetails;
