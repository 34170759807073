import Box from "@mui/material/Box";
import { Theme, styled, useTheme } from "@mui/material/styles";
import MainAppBar from "components/MainAppBar";
import * as React from "react";
import { Outlet } from "react-router-dom";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MiniDrawer() {
  const theme: Theme = useTheme();

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      const rootElement = document.documentElement;
      rootElement.style.setProperty(
        "--screen-height",
        `${window.innerHeight}px`
      );
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        background:
          "linear-gradient(120deg, #2955B7 2.7%, #337DC4 55.63%, #337DC4 98.93%)",
      }}
    >
      <img
        alt=""
        src="/img/frame.png"
        height="100%"
        width="auto"
        style={{ position: "absolute", pointerEvents: "none", opacity: 0.5 }}
      />
      <img
        alt=""
        src="/img/Orbit.png"
        height="100%"
        width="auto"
        style={{
          position: "absolute",
          width: "55%",
          maxWidth: "650px",
          left: "50%",
          top: 220,
          height: "auto",
          transform: "translateX(-50%)",
          pointerEvents: "none",
        }}
      />
      <MainAppBar open={true} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          position: "relative",
          minHeight: "var(--screen-height)",
          paddingInline: theme.spacing(6),
          paddingBottom: theme.spacing(8),
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
