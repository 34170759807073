import { Box, ButtonBase } from "@mui/material";
import { ICompany } from "data/company";
import { ROUTES } from "helpers/routes";
import { Link } from "react-router-dom";
import urlcat from "urlcat";

const Companies = ({ data }: { data: ICompany[] }) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" flexWrap="wrap" gap={2.5}>
        {data.map((company, index) => (
          <ButtonBase
            key={index}
            component="span"
            style={{
              height: 130,
              display: "flex",
              flex: "1 0 160px",
              borderRadius: 24,
              overflow: "hidden",
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to={urlcat(ROUTES.COMPANY_DETAILS, { slug: company.slug })}
              style={{
                padding: 16,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src={company.logo}
                alt={company.name}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Link>
          </ButtonBase>
        ))}
      </Box>
    </Box>
  );
};

export default Companies;
