import { Box } from "@mui/material";
import Companies from "components/Companies";
import { ICompany, getAllCompanies } from "data/company";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

const Home = () => {
  const [companies, setCompanies] = useState<ICompany[]>([]);

  useEffect(() => {
    setCompanies(getAllCompanies());
  }, []);

  return (
    <Box position="relative" bottom={0} width="100%">
      <Companies data={companies} />
      <Outlet />
    </Box>
  );
};

export default Home;
