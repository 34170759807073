import { Stack, Typography } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import AppLogo from "components/AppLogo";
import ElevationScroll from "components/ElevationScroll";
// import LanguageSwitcher from "components/LanguageSwitcher";
import React from "react";
import theme from "theme";

interface MainAppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<MainAppBarProps>(({ theme, open }) => ({
  color: `${theme.palette.common.white} !important`,
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: "transparent !important",
  paddingInline: theme.spacing(6),
  paddingBlock: theme.spacing(12),
  // backgroundColor: theme.palette.common.white,
  // borderBottom: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create(["box-shadow", "width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: theme.configs.menuWidth,
    // width: `calc(100% - ${theme.configs.menuWidth}px)`,
    transition: theme.transitions.create(["box-shadow", "width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    // marginLeft: theme.configs.menuCollapsedWidth,
    // width: `calc(100% - ${theme.configs.menuCollapsedWidth}px - 1px)`,
    transition: theme.transitions.create(["box-shadow", "width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainAppBar: React.FC<MainAppBarProps> = ({ open, ...props }) => {
  return (
    <ElevationScroll {...props}>
      <AppBar open={open}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <AppLogo />
          <Typography
            width={530}
            textAlign="center"
            fontSize={30}
            fontWeight={theme.typography.fontWeightMedium}
          >
            Welcome to Orbit Ho Chi Minh City by Genesia.
          </Typography>
          <Stack direction="row" spacing={1}>
            {/* <LanguageSwitcher /> */}
          </Stack>
        </Stack>
      </AppBar>
    </ElevationScroll>
  );
};

export default MainAppBar;
