import { AppRoutes } from "Routes";
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";

function App() {
  useEffect(() => {
    window.addEventListener("resize", () => {
      const rootElement = document.documentElement;
      rootElement.style.setProperty(
        "--screen-height",
        `${window.innerHeight}px`
      );
    });
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
