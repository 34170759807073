import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Theme,
  Toolbar,
  Typography,
  useTheme
} from "@mui/material";
import { useRequest } from "ahooks";
import AppLogo from "components/AppLogo";
import LanguageSwitcher from "components/LanguageSwitcher";
import { ROUTES } from "helpers/routes";
import storageUtils from "helpers/storageUtils";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { serviceLogin } from "services/api/login";
import * as yup from "yup";
import BgImg from "./bg.png";

const Login = () => {
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t("login.emailRequired"))
      .email(t("login.emailFormat")),
    password: yup.string().required(t("login.passwordRequired")),
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<LoginParamsType>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const [loginError, setLoginError] = useState<any>(undefined);
  const { loading, run: doLogin } = useRequest(serviceLogin, {
    manual: true,
    onSuccess: (data: LoginResponseType) => {
      setLoginError(undefined);
      storageUtils.saveAccessToken(data.token.accessToken);
      const redirect = new URLSearchParams(window.location.search).get(
        "redirect"
      );
      window.location.href = redirect ?? ROUTES.HOME;
    },
    onError: (error) => {
      setLoginError(error);
    },
  });
  const disabledSubmitButton =
    !isDirty || Object.keys(errors).length > 0 || !isValid || loading;

  const handleOnClickSubmit = (formData: LoginParamsType) => {
    console.log({ formData });
    doLogin(formData);
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        style={{
          width: "50%",
          left: 0,
          background: theme.palette.background.default,
        }}
      >
        <Toolbar>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            width="100%"
          >
            <AppLogo />
            <Box color={theme.palette.text.primary}>
              <LanguageSwitcher />
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>

      <Grid container>
        <Grid item xs={6}>
          <Stack
            justifyContent="center"
            alignItems="center"
            style={{ height: "var(--screen-height)" }}
          >
            <Card elevation={4}>
              <CardContent>
                <Box color={theme.palette.primary.main}>
                  <Typography
                    color="inherit"
                    variant="h5"
                    align="center"
                    marginBottom={4}
                  >
                    {t("login.login")}
                  </Typography>
                </Box>

                <Stack spacing={3}>
                  {loginError && (
                    <Alert severity="error">
                      {t("login.infoErrorMessage")}
                    </Alert>
                  )}
                  <Box>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="email"
                          label={t("login.email")}
                          margin="normal"
                          error={!!errors.email}
                          helperText={errors.email?.message}
                        />
                      )}
                    />
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="password"
                          label={t("login.password")}
                          margin="normal"
                          error={!!errors.password}
                          helperText={errors.password?.message}
                        />
                      )}
                    />
                  </Box>
                  <Button
                    fullWidth
                    disabled={disabledSubmitButton}
                    variant="contained"
                    onClick={handleSubmit(handleOnClickSubmit)}
                  >
                    {t("login.login")}
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${BgImg})`,
              height: "var(--screen-height)",
            }}
          ></Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
