
const companies = [
  {
    name: 'BuyMed',
    slug: 'buymed',
    industry: ['Healthcare'],
    stage: 'Achieved Product Market Fit and Scaling',
    market: 'Regional Focus - Established in Vietnam, Thailand, and Cambodia',
    url: 'https://buymed.com/en/',
    logo: '/img/companies/logos/BuyMed.png',
    description: 'Buymed is a trusted supplier of high-quality pharmaceuticals and medical equipment to over 35,000 pharmacies nationwide, capturing 20% of Vietnam\'s market share. Additionally, Buymed is growing its B2B2C model by partnering with over 1,000 esteemed pharmaceutical companies.',
    founder: {
      name: 'Hoang Nguyen',
      title: 'Co-founder & CEO',
      photo: '/img/companies/founders/BuyMed.webp',
      past: [
        {
          title: 'Ex - Strategy Consultant at Solidiance',
          logo: ['Solidiance.png'],
        }, {
          title: 'Forbes Asia 30 under 30',
        },
      ]
    },
  },
  {
    name: 'Kamereo',
    slug: 'kamereo',
    industry: ['Agriculture'],
    stage: 'Achieved Product Market Fit and Scaling',
    market: 'Vietnam Focus',
    url: 'https://kamereo.vn/',
    logo: '/img/companies/logos/Kamereo.png',
    description: 'All-in-one solutions for F&B businesses in Vietnam, leveraging their extensive supply chain networks to provide a farm-to-business service.',
    founder: {
      name: 'Taku Tanaka',
      title: 'Co-founder & CEO',
      photo: '/img/companies/founders/Kamereo.webp',
      past: [
        {
          title: 'Ex-COO at Pizza 4P\'s',
          logo: ['Pizza-4Ps.png'],
        }, {
          title: 'Ex-IB at Credit Suisse',
          logo: ['Credit-Suisse.png'],
        },
        {
          title: 'Ex - Credit Suisse'
        }
      ]
    },
  },
  {
    name: 'Vietcetera',
    slug: 'vietcetera',
    industry: ['Media'],
    stage: 'Achieved Product Market Fit and Scaling',
    market: 'Regional Focus',
    url: 'https://vietcetera.com/',
    logo: '/img/companies/logos/Vietcetera.png',
    description: 'Vietcetera is a leading media platform that focuses on high-quality content for well-educated GenZ. Boasting over 500,000 monthly active users, Vietcetera proudly collaborates with 700+ esteemed brands, including Meta and Masterclass, to curate compelling narratives and experiences.',
    founder: {
      name: 'Hao Tran',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/Vietcetera.webp',
      past: [
        {
          title: 'Ex - Investment Associate at 500 Startups',
          logo: ['500-Startups.png'],
        }, {
          title: 'Graduated from Brown University, UCL',
          logo: ['Brown-University.png'],
        },
      ]
    }
  },
  {
    name: 'Wareflex',
    slug: 'wareflex',
    industry: ['Warehouse Logistics'],
    stage: 'Early Stage',
    market: 'Regional Focus ',
    url: 'https://www.wareflex.io/en',
    logo: '/img/companies/logos/Wareflex.png',
    description: 'Wareflex is a leading B2B logistics platform in Vietnam, with a mission is to provide transparent, flexible, and cost-efficient contract logistics services to businesses of all sizes to explore and access a wide range of pre-verified logistics providers, including warehouses, transportation, and industrial real estate.',
    founder: {
      name: 'Rajnish Sharma',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/Wareflex.webp',
      past: [
        {
          title: 'Ex - Head of Retail Development at TH Group JSC',
          logo: ['TH-Group-JSC.png'],
        }
      ]
    }
  },
  {
    name: 'CaSe',
    slug: 'case',
    industry: ['E-commerce'],
    market: 'Regional Focus',
    stage: 'Approaching Product Market Fit',
    url: 'https://selly.vn/',
    logo: '/img/companies/logos/CaSe.jpg',
    description: 'CaSe is a pioneering embedded e-commerce solution seamlessly integrating Cashbag\'s affiliate cashback feature into leading bank and e-wallet applications such as MB, Shinhan, TP Bank, VP Bank, SHB, and BIDV. Also, CaSe has a robust social reseller network to amplify brand reach and drive sales. This innovative approach revolutionizes product distribution, offering brands a scalable and efficient avenue to showcase and sell their offerings.',
    founder: {
      name: 'Tuan Thong',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/CaSe.webp',
      past: [
        {
          title: 'Founder & Chairman of DFB Media',
          logo: ['DFB-Media.png'],
        }
      ]
    }
  },
  {
    name: 'MVillage',
    slug: 'mvillage',
    industry: ['Real Estate'],
    stage: 'Achieved Product Market Fit and Scaling',
    market: 'Vietnam Focus',
    url: 'https://mvillage.vn/en/ve-mvillage',
    logo: '/img/companies/logos/MVillage.png',
    description: 'MVillage provides living solutions for young professionals and tech-enabled property management services for landlords in Vietnam with more than 30 locations and over 1000 rooms.',
    founder: {
      name: 'Ninh Nguyen',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/MVillage.webp',
      past: [
        {
          title: 'Ex-Co-founder & CEO at The Coffee House',
          logo: ['The-Coffee-House.png'],
        },
        {
          title: 'Ex- Management Trainee at PepsiCo',
          logo: ['PepsiCo.png'],
        }
      ]
    }
  },
  {
    name: 'Manabie',
    slug: 'manabie',
    industry: ['EdTech'],
    stage: 'Achieved Product Market Fit and Scaling',
    market: 'Regional Focus - Japan & Vietnam',
    url: 'https://www.manabie.com/',
    logo: '/img/companies/logos/Manabie.png',
    description: 'Manabie is a global edtech company. Our mission is to prepare us for tomorrow’s world by catalyzing positive change for learners, society, and nature. We are dedicated to helping our learners discover and cultivate their inner greatness, and grow into curious, courageous, compassionate, and competent individuals.',
    founder: {
      name: 'Takuya Homma',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/Manabie.jpg',
      past: [
        {
          title: 'Ex- Co-founder at Quipper Limited',
          logo: ['Quipper-Limited.png'],
        }, {
          title: 'UCL & University of Tokyo',
          logo: ['UCL.png', 'University-of-Tokyo.png'],
        },
      ]
    }
  },
  {
    name: 'Fundiin',
    slug: 'fundiin',
    industry: ['Fintech'],
    stage: 'Achieved Product Market Fit and Scaling',
    market: 'Vietnam Focus',
    url: 'https://fundiin.vn/about',
    logo: '/img/companies/logos/Fundiin.png',
    description: 'Fundiin is the leading provider of Buy Now Pay Later solutions in Vietnam, dedicated to revolutionizing the fintech industry in Southeast Asia. Our paylater products empower merchants to boost their sales while providing consumers with the most financially efficient payment option and the most friendly user experience.',
    founder: {
      name: 'Cuong Nguyen',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/Fundiin.webp',
      past: [
        {
          title: 'Ex- Investment Manager at Vietnam Holding Asset Management Ltd.',
          logo: ['Vietnam-Holding-Asset-Management-Ltd.png'],
        },
        {
          title: 'CFA Charterholder',
        },
      ]
    }
  },
  {
    name: 'Rootopia',
    slug: 'rootopia',
    industry: ['Edtech'],
    stage: 'Approaching Product Market Fit',
    market: 'Vietnam Focus',
    url: 'https://rootopia.vn/about-us/',
    logo: '/img/companies/logos/Rootopia.png',
    description: 'A pioneer and leading player providing educational financing, building a high-engaged and well-educated customer to serve lifelong financial needs. Rootopia proudly to manage the NPL below 1.5%.',
    founder: {
      name: 'Truong Nguyen',
      title: 'Co-Founder & CEO',
      photo: '/img/companies/founders/Rootopia.webp',
      past: [
        {
          title: 'Ex-Co-founder & CEO at Ahamove',
          logo: ['Ahamove.png'],
        },
        {
          title: 'Ex- Director of Social Payment at Momo',
          logo: ['Momo.png'],
        }
      ]
    }
  },
  {
    name: 'Homedy',
    slug: 'homedy',
    industry: ['Real Estate'],
    stage: 'Early Stage',
    market: 'Vietnam Focus',
    url: 'https://homedy.com/gioi-thieu',
    logo: '/img/companies/logos/Homedy.png',
    description: 'Homedy (Homedy Inc.) is the No. 1 real estate connection platform in Vietnam based on 3 key factors: Technology, Real Estate and Marketing.',
    founder: {
      name: 'Duc Nguyen',
      photo: '/img/companies/founders/Homedy.jpg',
      past: [
        {
          title: 'Ex-CEO at Moore Corporation',
          logo: ['Moore-Corporation.png'],
        }
      ]
    }
  },
  {
    name: 'VCA Coffee',
    slug: 'vca-coffee',
    industry: ['Agriculture'],
    stage: 'Approaching Product Market Fit',
    market: 'Vietnam Focus',
    url: 'https://vcacoffee.com/ve-chung-toi/',
    logo: '/img/companies/logos/VCA-Coffee.png',
    description: 'The new coffee production and supply chain platform in Vietnam. VCA has R&D biological methods for outstanding high-yield, organic, and sustainable coffee quality.',
    founder: {
      name: 'Long Nguyen',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/VCA-Coffee.webp',
      past: [
        {
          title: 'More than 25 years of experience in Vietnamese coffee',
        },
        {
          title: 'Ex-Founder & CEO of Shin Coffee ',
          logo: ['Shin-Coffee.png']
        }
      ]
    }
  },
  {
    name: '24hmoney',
    slug: '24hmoney',
    industry: ['Fintech'],
    stage: 'Approaching Product Market Fit ',
    market: 'Vietnam Focus',
    url: 'https://24hmoney.vn',
    logo: '/img/companies/logos/24hmoney.jpg',
    description: '24HMoney provides a platform to connect the buy sides and sell sides for purpose of the capital arrangement for underserved yet high-quality private companies. By doing so.',
    founder: {
      name: 'Tam Nguyen',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/24hmoney.png',
      past: [
        {
          title: 'Ex-First generation of FPT',
          logo: ['FPT.png'],
        },
        {
          title: 'Founder of Simple Tech Investment (STI)',
          logo: ['STI.png']
        }
      ]
    }
  },
  {
    name: 'Acall',
    slug: 'acall',
    industry: ['Workplace Management'],
    market: 'Asia Focus',
    stage: 'Achieved Product Market Fit and Scaling',
    url: '',
    logo: '/img/companies/logos/Acall.svg',
    description: 'Acall Portal enables to set up and record the detailed logs of check-in/check-out, office reception/entry/exit, meeting room booking system usage integrated with Acall Reception and Acall Meeting. Acall enables businesses to use the collected data for analysis to improve your company\'s work environment.',
    founder: {
      name: 'Yoshi Naganuma',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/Acall.png',
      past: [
        {
          title: 'Ex-Solution Specialist IBM',
          logo: ['IBM.png'],
        },
        {
          title: 'Kobe University',
          logo: ['Kobe-University.png']
        }
      ]
    }
  },
  {
    name: 'Edoctor',
    slug: 'edoctor',
    industry: ['Healthtech'],
    stage: 'Early Stage',
    market: 'Vietnam Focus',
    url: '',
    logo: '/img/companies/logos/Edoctor.jpg',
    description: 'Healthcare platform starting from health check for individuals (B2C & B2B approach), then expanding to Hospital Management System offering to hospital clients in Vietnam.',
    founder: {
      name: 'Long Vu',
      title: 'Founder & CEO',
      photo: '/img/companies/founders/eDoctor.png',
      past: [
        {
          title: '10 years of experience in Healthcare',
        }
      ]
    }
  }
];

export interface IFounder {
  name: string;
  title: string;
  photo: string;
  past: {
    title: string;
    logo?: string[];
  }[];
}
export interface ICompany {
  name: string;
  slug: string;
  industry: string[];
  stage: string;
  market: string;
  url: string;
  logo: string;
  description: string;
  founder: IFounder;
};

export function getAllCompanies(): ICompany[] {
  return companies as ICompany[];
}

export function getCompanyBySlug(slug: string): ICompany {
  return companies.find((company) => company.slug === slug) as ICompany;
}
