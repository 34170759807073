import AppLayout from "components/Layouts";
import { ROUTES } from "helpers/routes";
import CompanyDetails from "pages/company";
import Home from "pages/home";
import Login from "pages/login";
import { Route, Routes, Navigate } from "react-router-dom";

export const AppRoutes = () => (
  <Routes>
    <Route path={ROUTES.LOGIN} element={<Login />} />
    <Route path={ROUTES.HOME} element={<AppLayout />}>
      <Route index element={<Navigate to={ROUTES.COMPANIES} replace />} />
      <Route path={ROUTES.COMPANIES} element={<Home />}>
        <Route path={ROUTES.COMPANY_DETAILS} element={<CompanyDetails />} />
      </Route>
    </Route>
  </Routes>
);